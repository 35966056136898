.mobile-header {
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .close-modal {
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 20px;
    border: none;
    background-color: transparent;
    font-size: 2.3rem;
  }

  .nav-container {
    color: #2e304b;
    flex-direction: column;
    gap: 6rem;

    .logo-wrap {
      .logo-name {
        color: #2e304b;
      }
    }

    .nav-links-wrap {
      flex-direction: column;

      li {
        a {
          color: #2e304b;
        }
      }
    }
  }
}

.hamburger-wrap {
  padding: 3rem 2rem;
  background-color: transparent;
  position: absolute;
  color: #fd8e8e;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 767px) {
  .hamburger-wrap {
    display: flex;
  }
}
