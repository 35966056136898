header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

header .nav-container {
  justify-content: space-between;
  align-items: center;
  max-width: 120rem;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: flex;
}

header .nav-container .logo-wrap {
  align-items: center;
  gap: .5rem;
  display: flex;
}

header .nav-container .logo-wrap .square {
  background-color: #0000;
  border: 2px solid #fd8e8e;
  width: 4rem;
  height: 4rem;
}

header .nav-container .logo-wrap .logo-name {
  color: #fff;
  font-size: 2rem;
}

header .nav-container .nav-links-wrap {
  align-items: center;
  gap: 3rem;
  list-style: none;
  display: flex;
}

header .nav-container .nav-links-wrap a {
  cursor: pointer;
  transition: all .3s;
}

header .nav-container .nav-links-wrap .hire {
  color: #fd8e8e;
  font-weight: 500;
}

@media (width <= 1200px) {
  header .nav-container {
    padding: 3rem 2rem;
  }
}

@media (width <= 767px) {
  header {
    display: none;
  }
}

html {
  font-family: Roboto, sans-serif;
  font-size: 65.2%;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1rem;
}

a {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
}

.sec-heading-wrap {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.sec-heading-wrap h2 {
  font-size: 3.2rem;
  font-weight: 600;
}

.sec-heading-wrap .header-line {
  background-color: #fd8e8e81;
  width: 15rem;
  height: 1px;
}

@media (width <= 1200px) {
  .container {
    padding: 0 2rem;
  }
}

@media (width <= 991px) {
  .sec-heading-wrap h2 {
    font-size: 2.6rem;
    font-weight: 600;
  }

  .sec-heading-wrap .header-line {
    width: 10rem;
  }
}

@media (width <= 575px) {
  .sec-heading-wrap h2 {
    font-size: 2.2rem;
    font-weight: 500;
  }

  .sec-heading-wrap .header-line {
    width: 6rem;
  }
}

#hero-sec {
  background-color: #2e304b;
  padding-top: 22rem;
  padding-bottom: 11rem;
  overflow: hidden;
}

#hero-sec .hero-flex {
  color: #fd8e8e;
  align-items: center;
  gap: 3rem;
  display: flex;
}

#hero-sec .hero-flex img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 34.5rem;
  height: 40rem;
}

#hero-sec .hero-flex h3 {
  opacity: 0;
  font-size: 3.2rem;
  font-weight: 400;
  transition: all .4s .1s;
  transform: translateX(-60px);
}

#hero-sec .hero-flex h1 {
  opacity: 0;
  margin-top: 1rem;
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1;
  transition: all .4s .3s;
  transform: translateX(-60px);
}

#hero-sec .hero-flex p {
  color: #fd8b8bcc;
  opacity: 0;
  margin-top: 3rem;
  font-weight: 400;
  transition: all .4s .5s;
  transform: translateX(-60px);
}

#hero-sec .hero-flex .hero-btns-wrap {
  opacity: 0;
  align-items: center;
  gap: 2rem;
  margin-top: 6rem;
  transition: all .4s .7s;
  display: flex;
  transform: translateX(-60px);
}

#hero-sec .hero-flex .hero-btns-wrap button {
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 1.8rem;
}

#hero-sec .hero-flex .hero-btns-wrap .hero-hire-btn {
  color: #fd8e8e;
  background-color: #0000;
  border: 2px solid #fd8e8e;
  border-radius: 100px;
  padding: 1.5rem 3rem;
}

#hero-sec .hero-flex .hero-btns-wrap .hero-viewProjects-btn {
  color: #fff;
  background-color: #0000;
  border: none;
}

#hero-sec .hero-flex span {
  color: #9697a2;
  opacity: 0;
  margin-top: 9rem;
  font-size: 1.6rem;
  transition: all .4s .9s;
  display: inline-block;
  transform: translateX(-60px);
}

#hero-sec .hero-flex span a {
  font-size: 1.6rem;
}

#hero-sec .hero-flex .hero-img {
  opacity: 0;
  transition: all .4s 1s;
  transform: translateX(60px);
}

@media (width <= 1200px) {
  #hero-sec {
    padding-top: 14rem;
    padding-bottom: 8rem;
  }

  #hero-sec .hero-flex h3 {
    font-size: 2.6rem;
  }

  #hero-sec .hero-flex h1 {
    font-size: 4rem;
  }

  #hero-sec .hero-flex p {
    font-size: 1.6rem;
  }

  #hero-sec .hero-flex .hero-btns-wrap .hero-hire-btn {
    padding: 1rem 2rem;
  }

  #hero-sec .hero-flex img {
    object-fit: cover;
    width: 30rem;
    height: 34.5rem;
  }
}

@media (width <= 991px) {
  #hero-sec .hero-flex h3 {
    font-size: 2.2rem;
  }

  #hero-sec .hero-flex h1 {
    font-size: 3.2rem;
  }

  #hero-sec .hero-flex p {
    font-size: 1.6rem;
  }

  #hero-sec .hero-flex .hero-btns-wrap .hero-hire-btn {
    padding: 1rem 2rem;
  }

  #hero-sec .hero-flex img {
    object-fit: cover;
    width: 24rem;
    height: 28rem;
  }
}

@media (width <= 767px) {
  #hero-sec .hero-flex {
    display: block;
  }

  #hero-sec .hero-flex span {
    margin-top: 2rem;
  }

  #hero-sec .hero-flex .hero-img-wrap {
    margin-top: 4rem;
  }
}

@media (width <= 575px) {
  #hero-sec {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  #hero-sec .hero-flex h3 {
    font-size: 2rem;
  }

  #hero-sec .hero-flex h1 {
    font-size: 2.8rem;
  }

  #hero-sec .hero-flex span, #hero-sec .hero-flex span a {
    font-size: 1.4rem;
  }

  #hero-sec .hero-flex .hero-btns-wrap {
    gap: 1rem;
  }

  #hero-sec .hero-flex .hero-btns-wrap .hero-hire-btn {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }

  #hero-sec .hero-flex .hero-btns-wrap .hero-viewProjects-btn {
    font-size: 1.6rem;
  }

  #hero-sec .hero-flex img {
    object-fit: cover;
    width: 18rem;
    height: 20.5rem;
  }
}

#about-sec {
  padding: 8rem 0;
}

#about-sec .about-first-p {
  margin-top: 4rem;
}

#about-sec .about-second-p {
  margin-top: 2rem;
}

@media (width <= 991px) {
  #about-sec .about-first-p, #about-sec .about-second-p {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

#skills-sec {
  background-color: #2e304b;
  padding: 8rem 0;
}

#skills-sec .skills-flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#skills-sec .skills-flex .skill-img-wrap {
  background-color: #ffffff0e;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  display: flex;
}

#skills-sec .skills-flex img {
  object-fit: contain;
  width: 5.7rem;
  height: 5.7rem;
}

@media (width <= 991px) {
  #skills-sec .skills-flex .skill-img-wrap {
    padding: 1rem;
  }

  #skills-sec .skills-flex img {
    width: 4.7rem;
    height: 4.7rem;
  }
}

@media (width <= 767px) {
  #skills-sec .skills-flex {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    display: grid;
  }
}

#projects-sec {
  padding: 8rem 0;
}

#projects-sec .project-wrap {
  margin-top: 4rem;
  margin-bottom: 30rem;
  position: relative;
}

#projects-sec .project-wrap .mobile-project-name {
  margin-bottom: 1rem;
  font-size: 2.6rem;
  font-weight: 500;
  display: none;
}

#projects-sec .project-wrap .project-sign-mobile {
  color: #6770f1;
  font-size: 1.4rem;
  display: none;
}

#projects-sec .project-wrap .project-content-wrap {
  text-align: end;
  position: absolute;
  bottom: -80%;
  right: 5%;
}

#projects-sec .project-wrap .project-content-wrap .project-sign {
  color: #6770f1;
  font-size: 1.6rem;
}

#projects-sec .project-wrap .project-content-wrap .project-name {
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-weight: 500;
}

#projects-sec .project-wrap .project-content-wrap .project-inner-wrap {
  color: #fff;
  text-align: start;
  background-color: #2e304b;
  width: 60rem;
  padding: 2rem;
  font-weight: 300;
  line-height: 1.5;
}

#projects-sec .project-wrap .project-content-wrap ul {
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  list-style: none;
  display: flex;
}

#projects-sec .project-wrap .project-content-wrap .preview-wrap {
  margin-top: 1rem;
}

#projects-sec .project-wrap .project-content-wrap a {
  color: #000;
  margin-right: 1rem;
  font-size: 2.2rem;
}

@media (width <= 1200px) {
  #projects-sec .project-wrap .project-content-wrap {
    bottom: -81%;
    right: 0%;
  }

  #projects-sec .project-wrap .project-content-wrap .project-sign {
    font-size: 1.4rem;
  }

  #projects-sec .project-wrap .project-content-wrap .project-name {
    font-size: 2.6rem;
  }

  #projects-sec .project-wrap .project-content-wrap p, #projects-sec .project-wrap .project-content-wrap .project-inner-wrap p {
    font-size: 1.6rem;
  }
}

@media (width <= 991px) {
  #projects-sec .project-wrap {
    margin-bottom: 6rem;
  }

  #projects-sec .project-wrap .mobile-project-name, #projects-sec .project-wrap .project-sign-mobile {
    display: block;
  }

  #projects-sec .project-wrap .project-content-wrap {
    text-align: start;
    position: relative;
  }

  #projects-sec .project-wrap .project-content-wrap .project-sign, #projects-sec .project-wrap .project-content-wrap .project-name {
    display: none;
  }

  #projects-sec .project-wrap .project-content-wrap p, #projects-sec .project-wrap .project-content-wrap .project-inner-wrap p {
    font-size: 1.6rem;
  }

  #projects-sec .project-wrap .project-content-wrap ul {
    justify-content: start;
  }
}

@media (width <= 767px) {
  #projects-sec img, #projects-sec .project-wrap .project-content-wrap .project-inner-wrap {
    width: 100%;
  }
}

@media (width <= 575px) {
  #projects-sec .project-wrap .mobile-project-name {
    font-size: 2.2rem;
  }

  #projects-sec .project-wrap .project-sign-mobile {
    font-size: 1.4rem;
  }
}

#getInTouch-sec {
  color: #2e304b;
  background-color: #fd8e8e;
  padding: 8rem 0;
}

#getInTouch-sec .form-inner-wrap {
  margin-top: 2rem;
}

#getInTouch-sec .form-inner-wrap label {
  font-size: 1.6rem;
  display: block;
}

#getInTouch-sec .form-inner-wrap input {
  width: 40rem;
  height: 3rem;
  padding-left: .5rem;
}

#getInTouch-sec .form-inner-wrap textarea {
  resize: none;
  width: 60rem;
  height: 30rem;
  padding: .5rem 1rem;
}

#getInTouch-sec .button {
  color: #2e304b;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #2e304b;
  border-radius: 100px;
  margin-top: 3rem;
  padding: 1.5rem 3rem;
  font-size: 1.8rem;
  font-weight: 600;
}

@media (width <= 991px) {
  #getInTouch-sec .button {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
}

@media (width <= 767px) {
  #getInTouch-sec .form-inner-wrap textarea {
    width: 40rem;
    height: 25rem;
  }
}

@media (width <= 575px) {
  #getInTouch-sec .form-inner-wrap input {
    width: 30rem;
  }

  #getInTouch-sec .form-inner-wrap textarea {
    width: 30rem;
    height: 25rem;
  }
}

.mobile-header {
  z-index: 999;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.mobile-header .close-modal {
  z-index: 10;
  background-color: #0000;
  border: none;
  font-size: 2.3rem;
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-header .nav-container {
  color: #2e304b;
  flex-direction: column;
  gap: 6rem;
}

.mobile-header .nav-container .logo-wrap .logo-name {
  color: #2e304b;
}

.mobile-header .nav-container .nav-links-wrap {
  flex-direction: column;
}

.mobile-header .nav-container .nav-links-wrap li a {
  color: #2e304b;
}

.hamburger-wrap {
  color: #fd8e8e;
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3rem 2rem;
  display: none;
  position: absolute;
}

@media (width <= 767px) {
  .hamburger-wrap {
    display: flex;
  }
}
/*# sourceMappingURL=index.80f9eac3.css.map */
