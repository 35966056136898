#projects-sec {
  padding: 8rem 0;

  .project-wrap {
    margin-top: 4rem;
    margin-bottom: 30rem;
    position: relative;

    .mobile-project-name {
      display: none;
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .project-sign-mobile {
      color: #6770f1;
      font-size: 1.4rem;
      display: none;
    }

    .project-content-wrap {
      position: absolute;
      bottom: -80%;
      right: 5%;
      text-align: end;

      .project-sign {
        color: #6770f1;
        font-size: 1.6rem;
      }

      .project-name {
        font-size: 3.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .project-inner-wrap {
        padding: 2rem;
        background-color: #2e304b;
        color: #fff;
        width: 60rem;
        text-align: start;
        font-weight: 300;
        line-height: 1.5;
      }

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 1rem;
        margin-top: 1rem;
      }

      .preview-wrap {
        margin-top: 1rem;
      }

      a {
        color: #000;
        margin-right: 1rem;
        font-size: 2.2rem;
      }
    }
  }
}

/*Responsive*/
@media (max-width: 1200px) {
  #projects-sec {
    .project-wrap {
      .project-content-wrap {
        bottom: -81%;
        right: 0%;

        .project-sign {
          font-size: 1.4rem;
        }

        .project-name {
          font-size: 2.6rem;
        }

        p {
          font-size: 1.6rem;
        }

        .project-inner-wrap {
          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #projects-sec {
    .project-wrap {
      margin-bottom: 6rem;
      .mobile-project-name {
        display: block;
      }

      .project-sign-mobile {
        display: block;
      }

      .project-content-wrap {
        position: relative;
        text-align: start;

        .project-sign {
          display: none;
        }

        .project-name {
          display: none;
        }

        p {
          font-size: 1.6rem;
        }

        .project-inner-wrap {
          p {
            font-size: 1.6rem;
          }
        }

        ul {
          justify-content: start;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #projects-sec {
    img {
      width: 100%;
    }

    .project-wrap {
      .project-content-wrap {
        .project-inner-wrap {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  #projects-sec {
    .project-wrap {
      .mobile-project-name {
        font-size: 2.2rem;
      }

      .project-sign-mobile {
        font-size: 1.4rem;
      }
    }
  }
}
