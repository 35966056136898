#getInTouch-sec {
  padding: 8rem 0;
  background-color: #fd8e8e;
  color: #2e304b;

  .form-inner-wrap {
    margin-top: 2rem;

    label {
      font-size: 1.6rem;
      display: block;
    }

    input {
      height: 3rem;
      width: 40rem;
      padding-left: 0.5rem;
    }

    textarea {
      resize: none;
      height: 30rem;
      width: 60rem;
      padding: 0.5rem 1rem;
    }
  }
  .button {
    margin-top: 3rem;
    color: #2e304b;
    border: 2px solid #2e304b;
    padding: 1.5rem 3rem;
    background-color: transparent;
    font-size: 1.8rem;
    font-weight: 600;
    border-radius: 100px;
    cursor: pointer;
  }
}

@media (max-width: 991px) {
  #getInTouch-sec .button {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  #getInTouch-sec {
    .form-inner-wrap {
      textarea {
        height: 25rem;
        width: 40rem;
      }
    }
  }
}

@media (max-width: 575px) {
  #getInTouch-sec {
    .form-inner-wrap {
      input {
        width: 30rem;
      }
      textarea {
        height: 25rem;
        width: 30rem;
      }
    }
  }
}
