#skills-sec {
  background-color: #2e304b;
  padding: 8rem 0;

  .skills-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .skill-img-wrap {
      padding: 1.5rem;
      background-color: hsla(0, 0%, 100%, 0.055);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 5.7rem;
      height: 5.7rem;
      object-fit: contain;
    }
  }
}

@media (max-width: 991px) {
  #skills-sec {
    .skills-flex {
      .skill-img-wrap {
        padding: 1rem;
      }
      img {
        width: 4.7rem;
        height: 4.7rem;
      }
    }
  }
}

@media (max-width: 767px) {
  #skills-sec {
    .skills-flex {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1rem;
    }
  }
}
