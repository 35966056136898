header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .nav-container {
    max-width: 120rem;
    margin: 0 auto;
    padding: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-wrap {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .square {
        width: 4rem;
        height: 4rem;
        border: 2px solid #fd8e8e;
        background-color: transparent;
      }

      .logo-name {
        color: #fff;
        font-size: 2rem;
      }
    }

    .nav-links-wrap {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 3rem;

      a {
        transition: all 0.3s;
        cursor: pointer;
      }

      .hire {
        color: #fd8e8e;
        font-weight: 500;
      }
    }
  }
}

/*Responsive*/
@media (max-width: 1200px) {
  header {
    .nav-container {
      padding: 3rem 2rem;
    }
  }
}

@media (max-width: 767px) {
  header {
    display: none;
  }
}

@media (max-width: 575px) {
}
