#about-sec {
  padding: 8rem 0;

  .about-first-p {
    margin-top: 4rem;
  }

  .about-second-p {
    margin-top: 2rem;
  }
}

@media (max-width: 991px) {
  #about-sec {
    .about-first-p {
      font-size: 1.6rem;
      line-height: 1.4;
    }

    .about-second-p {
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }
}
