#hero-sec {
  padding-top: 22rem;
  padding-bottom: 11rem;
  background-color: #2e304b;
  overflow: hidden;

  .hero-flex {
    display: flex;
    align-items: center;
    gap: 3rem;
    color: #fd8e8e;

    img {
      width: 34.5rem;
      height: 40rem;
      object-fit: cover;
      object-position: 50% 50%;
    }

    h3 {
      font-size: 3.2rem;
      font-weight: 400;
      transform: translateX(-60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 0.1s;
    }

    h1 {
      font-size: 4.8rem;
      font-weight: 400;
      line-height: 1;
      margin-top: 1rem;
      transform: translateX(-60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 0.3s;
    }

    p {
      font-weight: 400;
      color: #fd8b8bcc;
      margin-top: 3rem;
      transform: translateX(-60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 0.5s;
    }

    .hero-btns-wrap {
      margin-top: 6rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      transform: translateX(-60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 0.7s;

      button {
        letter-spacing: 1px;
        font-size: 1.8rem;
        cursor: pointer;
      }

      .hero-hire-btn {
        background-color: transparent;
        border: 2px solid #fd8e8e;
        border-radius: 100px;
        padding: 1.5rem 3rem;
        color: #fd8e8e;
      }

      .hero-viewProjects-btn {
        background-color: transparent;
        border: none;
        color: #fff;
      }
    }

    span {
      display: inline-block;
      font-size: 1.6rem;
      margin-top: 9rem;
      color: #9697a2;
      transform: translateX(-60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 0.9s;

      a {
        font-size: 1.6rem;
      }
    }

    .hero-img {
      transform: translateX(60px);
      opacity: 0;
      transition: all 0.4s;
      transition-delay: 1s;
    }
  }
}

/*Responsive*/
@media (max-width: 1200px) {
  #hero-sec {
    padding-top: 14rem;
    padding-bottom: 8rem;

    .hero-flex {
      h3 {
        font-size: 2.6rem;
      }

      h1 {
        font-size: 4rem;
      }

      p {
        font-size: 1.6rem;
      }

      .hero-btns-wrap {
        .hero-hire-btn {
          padding: 1rem 2rem;
        }
      }

      img {
        width: 30rem;
        height: 34.5rem;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 991px) {
  #hero-sec {
    .hero-flex {
      h3 {
        font-size: 2.2rem;
      }

      h1 {
        font-size: 3.2rem;
      }

      p {
        font-size: 1.6rem;
      }

      .hero-btns-wrap {
        .hero-hire-btn {
          padding: 1rem 2rem;
        }
      }

      img {
        width: 24rem;
        height: 28rem;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 767px) {
  #hero-sec {
    .hero-flex {
      display: block;

      span {
        margin-top: 2rem;
      }

      .hero-img-wrap {
        margin-top: 4rem;
      }
    }
  }
}

@media (max-width: 575px) {
  #hero-sec {
    padding-top: 10rem;
    padding-bottom: 6rem;
    .hero-flex {
      h3 {
        font-size: 2rem;
      }

      h1 {
        font-size: 2.8rem;
      }

      span {
        font-size: 1.4rem;

        a {
          font-size: 1.4rem;
        }
      }

      .hero-btns-wrap {
        gap: 1rem;
        .hero-hire-btn {
          padding: 1rem 2rem;
          font-size: 1.6rem;
        }

        .hero-viewProjects-btn {
          font-size: 1.6rem;
        }
      }

      img {
        width: 18rem;
        height: 20.5rem;
        object-fit: cover;
      }
    }
  }
}
