html {
  font-size: 65.2%;
  font-family: "Roboto", sans-serif;
}

body {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1rem;
}

a {
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
}

.sec-heading-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;

  h2 {
    font-size: 3.2rem;
    font-weight: 600;
  }

  .header-line {
    width: 15rem;
    height: 1px;
    background-color: #fd8e8e81;
  }
}

/*Responsive*/
@media (max-width: 1200px) {
  .container {
    padding: 0 2rem;
  }
}

@media (max-width: 991px) {
  .sec-heading-wrap {
    h2 {
      font-size: 2.6rem;
      font-weight: 600;
    }

    .header-line {
      width: 10rem;
    }
  }
}

@media (max-width: 575px) {
  .sec-heading-wrap {
    h2 {
      font-size: 2.2rem;
      font-weight: 500;
    }

    .header-line {
      width: 6rem;
    }
  }
}
